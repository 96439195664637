<template>
  <div class="contenedor-cpanel">
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Crear Entrega de Venta</h1>
        <div class="barra"></div>
      </div>
      <router-link :to="`/listar-ventas-no-entregadas`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    </section>
    <p>Nombre y Apellido: {{ this.venta.nombre }} {{ this.venta.apellido }}</p>
    <p>Email: {{ this.venta.email }} </p>
    <p>Celular: {{ this.venta.celular }} </p>
    <p>Monto: {{ this.venta.precio_total }} </p>
    <ul class="puntos_entrega_productos_lista">
      <ProductosVendidosListaItem
        v-for="item in venta.productos"
        :item="item"
        :key="item.producto_id"
      ></ProductosVendidosListaItem>
    </ul>
    <form
      action="#"
      method="post"
      @submit.prevent="crearEntrega(pedido)"
      class="form_presupuesto_nuevo"
    >
      <div class="form_section_editar_socio">
        <label for="toma_clase">Seleccionar un punto de entrega</label>
        <div class="contenedor_select_buscador">
          <v-select
            class="select_buscador minimal"
            v-model.number="pedido.punto_de_entrega_id"
            label="item_data"
            :reduce="socios => socios.punto_de_entrega_id"
            :options="socios"
            :disabled="cargando"
          >
            <span slot="no-options" @click="$refs.select.open = false" class="select_buscador_warning">
              <p>⚠️ Lamentablemente ningún punto de entrega tiene el stock suficiente para completar la venta ⚠️</p>
            </span>
          </v-select>
          <div
          class="form_mensaje_error_crear_pago"
            v-if="errores.punto_de_entrega_id !== null"
          >
            {{ errores.punto_de_entrega_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="fecha_de_entrega">Fecha de Entrega o Envío</label>
          <input
            type="date"
            name="fecha_de_entrega"
            id="fecha_de_entrega"
            v-model="pedido.fecha_de_entrega"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error_crear_pago"
            v-if="errores.fecha_de_entrega !== null"
          >
            {{ errores.fecha_de_entrega[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="forma_de_entrega_id">Forma de Entrega</label>
          <select
            id="forma_de_entrega_id"
            name="forma_de_entrega_id"
            class="form minimal"
            v-model.number="pedido.forma_de_entrega_id"
            :disabled="cargando"
          >
            <option
              v-for="forma in formasDeEntrega"
              :value="forma.forma_de_entrega_id"
              :key="forma.forma_de_entrega_id"
            >
              {{ forma.nombre }}
            </option>
          </select>
          <div
          class="form_editar_mensaje_error"
            v-if="errores.forma_de_entrega_id !== null"
          >
            {{ errores.forma_de_entrega_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="descripcion">Descripción</label>
          <textarea
            name="descripcion"
            id="descripcion"
            v-model.number="pedido.descripcion"
          ></textarea>
        </div>
      </div>
      <button type="submit" class="boton_guardar">Completar Entrega</button>
    </form>
  </div>
</template>

<script>
import ProductosVendidosListaItem from '../components/ProductosVendidosListaItem.vue'
import ventasService from '../services/ventas'
import entregasService from '../services/entregas'
export default {
  name: 'CrearEntregaVenta',
  components: {
    ProductosVendidosListaItem
  },
  data: function () {
    return {
      venta: [],
      socios: [],
      formasDeEntrega: [],
      cargando: false,
      pedido: {
        punto_de_entrega_id: null,
        fecha_de_entrega: null,
        forma_de_entrega_id: null,
        venta_id: this.$route.params.id,
        descripcion: null
      },
      errores: {
        forma_de_entrega_id: null,
        punto_de_entrega_id: null,
        fecha_de_entrega: null
      }
    }
  },
  methods: {
    crearEntrega (pedido) {
      console.log('qqqq')
      console.log(pedido)
      if (this.cargando) {
        return null
      }
      this.errores = {
        forma_de_entrega_id: null,
        punto_de_entrega_id: null,
        fecha_de_entrega: null
      }
      this.cargando = true
      ventasService
        .crearEntrega(pedido)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            this.socio = {
              persona_id: null
            }
            this.errores = {
              forma_de_entrega_id: null,
              punto_de_entrega_id: null,
              fecha_de_entrega: null
            }
            this.$router.push('/listar-ventas-no-entregadas')
          } else {
            this.errores = {
              forma_de_entrega_id: null,
              punto_de_entrega_id: null,
              fecha_de_entrega: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    ventasService
      .traerPosiblesPuntosDeEntregaParaVenta(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.socios = respuesta
        console.log('Punto de Entrega:', respuesta)
        this.socios.map(function (x) {
          // const pepe = x.nombre + ' ' + x.apellido
          // console.log(pepe)
          x.item_data = x.nombre + ' ' + x.apellido
          // return x.item_data = pepe
        })
      })
    console.log('aaaassssssaaaa')
    console.log(this.$route.query.collection_id)
    console.log(this.$route.query.pepe)
    ventasService
      .traerVentaPorId(this.$route.params.id)
      .then(respuesta => {
        this.venta = respuesta.data
        console.log(respuesta)
      })
    entregasService
      .traerFormasDeEntrega()
      .then(respuesta => {
        // this.cargando = false
        this.formasDeEntrega = respuesta
      })
  }
}
</script>

<style>

</style>
