<template>
  <div class="contenedor-editar-datos-socio">
    <div class="contenedor-titulo">
      <h1>Editar Punto De Entrega</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/puntos-de-entrega`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section>
      <div class="contenedor-editar-datos-socio-izquierda">
        <img src="@/assets/patanjali.jpg" alt="Patanjali">
      </div>
      <div class="contenedor-editar-datos-socio-derecha">
        <h2>{{ punto.persona_nombre }} {{ punto.persona_apellido }}</h2>
        <div>
          <form
            action="#"
            method="post"
            @submit.prevent="editarStock(punto.stock)"
            class="form_presupuesto_nuevo"
          >
            <EditarStockPuntoDeEntrega
              v-for="producto in punto.stock"
              :producto="producto"
              :errores2="errores2"
              :key="producto.producto_id + '.' + producto.color"
              @refrescar="refrescarEditarPuntoDeEntrega"
            ></EditarStockPuntoDeEntrega>
            <button type="submit" class="boton_guardar">Editar Stock</button>
          </form>
        </div>
        <div>
          <form
            action="#"
            method="post"
            @submit.prevent="agregarProducto(producto)"
            class="form_presupuesto_nuevo"
          >
            <div class="form_section">
              <label for="producto_id">Agregar Producto</label>
              <select
                id="producto_id"
                name="producto_id"
                class="form minimal"
                v-model.number="producto.producto_id"
                :disabled="cargando"
                @change="traerVariablesDelProducto(producto.producto_id)"
              >
                <option
                  v-for="producto in productos"
                  :value="producto.producto_id"
                  :key="producto.producto_id"
                >
                  {{ producto.producto_nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error"
                v-if="errores.producto_id !== null"
              >
                {{ errores.producto_id[0] }}
              </div>
            </div>
            <div class="form_section" v-if="mostrarTalle">
              <label for="talle">Talle</label>
              <select
                id="talle_id"
                name="talle_id"
                class="form minimal"
                v-model.number="producto.talle_id"
                :disabled="cargando"
                @change="traerColorDelTalle(producto.producto_id,producto.talle_id)"
              >
                <option
                  v-for="talle in talles"
                  :value="talle.talle_id"
                  :key="talle.talle_id"
                >
                  {{ talle.talle_nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error"
                v-if="errores.talle_id !== null"
              >
                {{ errores.talle_id[0] }}
              </div>
            </div>
            <div class="form_section" v-if="mostrarColor">
              <label for="talle">Color</label>
              <select
                id="color_id"
                name="color_id"
                class="form minimal"
                v-model.number="producto.color_id"
                :disabled="cargando"
                @change="chequeoProductoExistente(producto.punto_de_entrega_id,producto.producto_id,producto.talle_id,producto.color_id)"

              >
                <option
                  v-for="color in colores"
                  :value="color.color_id"
                  :key="color.color_id"
                >
                  {{ color.color_nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error"
                v-if="errores.color_id !== null"
              >
                {{ errores.color_id[0] }}
              </div>
            </div>
            <div class="form_section" v-if="mostrarStock">
              <label for="stock">Stock</label>
              <input
                type="text"
                name="stock"
                id="stock"
                v-model="producto.stock"
                :disabled="cargando"
              >
              <div
              class="form_mensaje_error"
                v-if="errores.stock !== null"
              >
                {{ errores.stock[0] }}
              </div>
            </div>
            <button type="submit" class="boton_guardar" v-if="mostrarStock">Agregar Producto</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import entregasService from '../services/entregas'
// import serviciosService from '../services/servicios'
import productosService from '../services/productos'
import EditarStockPuntoDeEntrega from '../components/EditarStockPuntoDeEntrega.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'EditarPuntoDeEntrega',
  components: {
    EditarStockPuntoDeEntrega
  },
  data: function () {
    return {
      servicio: {
        stock: null
      },
      punto: {},
      productos: [],
      talles: [],
      colores: [],
      producto: {
        producto_id: null,
        stock: null,
        talle_id: null,
        color_id: null,
        punto_de_entrega_id: this.$route.params.id
      },
      errores: {
        stock: null,
        talle_id: null,
        color_id: null,
        producto_id: null
      },
      errores2: {
        // '0.stock': null,
        // producto_id: null
      },
      cargando: false,
      mostrarStock: false,
      mostrarTalle: false,
      mostrarColor: false
    }
  },
  methods: {
    chequeoProductoExistente (puntoDeEntregaId, productoId, talleId, colorId) {
      this.mostrarStock = false
      console.log(puntoDeEntregaId)
      productosService
        .chequeoProductoExistente(puntoDeEntregaId, productoId, talleId, colorId)
        .then(respuesta => {
          console.log(respuesta)
          if (respuesta.success) {
            this.mostrarStock = true
          } else {
            console.log('ESTA DUPLICADO')
            this.errores.color_id = ['El punto de entrega ya tiene ese producto. Por favor editar el stock']
          }
        })
    },
    traerColorDelTalle (productoId, talleId) {
      this.mostrarStock = false
      this.mostrarColor = false
      this.errores = {
        producto_id: null,
        stock: null,
        talle_id: null,
        color_id: null
      }
      productosService
        .traercoloresFaltantes(productoId, talleId)
        .then(respuesta => {
          this.mostrarColor = true
          this.colores = respuesta
        })
    },
    traerVariablesDelProducto (id) {
      this.mostrarStock = false
      this.mostrarColor = false
      this.mostrarTalle = false
      this.errores = {
        producto_id: null,
        stock: null,
        talle_id: null,
        color_id: null
      }
      console.log(id)
      productosService
        .traerTallesFaltantes(id)
        .then(respuesta => {
          console.log(respuesta)
          if (respuesta.length !== 0) {
            this.mostrarTalle = true
            this.talles = respuesta
          } else {
            this.mostrarStock = true
          }
        })
    },
    agregarProducto (producto) {
      console.log(producto)
      productosService
        .agregarProducto(producto)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.refrescarEditarPuntoDeEntrega()
            this.producto = {
              producto_id: null,
              talle_id: null,
              color_id: null,
              stock: null
            }
            this.errores = {
              producto_id: null,
              stock: null,
              talle_id: null,
              color_id: null
            }
            this.$router.push('/puntos-de-entrega')
          } else {
            // console.log(respuesta.errores[0])
            this.errores = {
              stock: null,
              producto_id: null,
              talle_id: null,
              color_id: null,
              ...respuesta.errors
            }
          }
        })
    },
    editarStock (punto) {
      console.log(punto)
      if (this.cargando) {
        return null
      }
      this.errores = {
        stock: null,
        producto_id: null
      }
      this.cargando = true
      productosService
        .editarStockPorPuntoDeEntregaId(punto)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.pago = {
              precio_total: null,
              fecha_de_pago: null,
              concepto_id: null,
              forma_de_pago_id: null,
              producto_id: null,
              year_id: null,
              moneda_id: null,
              descripcion: null
            }
            this.errores = {
              stock: null,
              producto_id: null
            }
            this.$router.push('/puntos-de-entrega')
          } else {
            console.log(respuesta.errors)
            this.errores2 = {
              stock: null,
              producto_id: null,
              ...respuesta.errors
            }
          }
        })
    },
    refrescarEditarPuntoDeEntrega () {
      productosService
        .traerPuntoDeEntregaPorId(this.$route.params.id)
        .then(respuesta => {
          // this.cargando = false
          this.punto = respuesta
          console.log('PUNTO DE ENTREGA', respuesta)
        })
      productosService
        .traerProductosFaltantesPuntoDeEntregaPorId(this.$route.params.id)
        .then(respuesta => {
          // this.cargando = false
          this.productos = respuesta
          console.log('Productos Faltantes: ', respuesta)
        })
    }
  },
  mounted: function () {
    // this.cargando = true
    // this.errores2 = {
    //   '0.stock': null,
    //   '1.stock': null
    // }
    productosService
      .traerPuntoDeEntregaPorId(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.punto = respuesta
        console.log('PUNTO DE ENTREGA', respuesta)
        for (var i = 0; i < respuesta.stock.length; i++) {
          this.errores2[i + '.stock'] = null
        }
      })
    productosService
      .traerProductosFaltantesPuntoDeEntregaPorId(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.productos = respuesta
        console.log('Productos Faltantes: ', respuesta)
      })
    // this.errores.productos.'/./'9'/./'.jkjk = null
  },
  computed: {
    mostrarCampoYear: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.pago.concepto_id) {
        case 1:
          return true
        case 2:
          return true
        case 3:
          return true
        default:
          return false
      }
    }
  }
}
</script>
