<template>
  <header class="contenedor-nav">
    <input type="checkbox" id="btn-menu">
      <label for="btn-menu" class="icon-bars2" @click="menuHamburguesa()" v-if="store.user.nombre !== null">
        <div id="nav-icon3" v-bind:class="{ open: isActive }">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </label>
    <a :href="this.url" class="logo-navegador">
      <img src="@/assets/logo-aayi.png" alt="Logo Asociación Argentina de Yoga Iyengar">
    </a>
    <nav class="contenedor_botones_nav">
      <router-link to="/datos-personales" class="boton-cpanel" v-if="store.user.nombre !== null" @click.native="cerrarMenu()">Panel del Socio</router-link>
      <router-link to="/difusion" class="boton-cpanel" @click.native="cerrarMenu()">Difusión</router-link>
      <!-- <router-link to="/difusion" class="boton-cpanel" v-if="store.user.perfil_id == 1" @click.native="cerrarMenu()">Difusión</router-link> -->
      <!-- <router-link to="/seminario-uday-socio" class="boton-cpanel"  v-if="store.user.nombre !== null"  @click.native="cerrarMenu()">Seminario</router-link> -->
      <router-link to="/panel-de-control" class="boton-cpanel" v-if="store.user.tipo_id == 1" @click.native="cerrarMenu()">CPanel</router-link>
      <button @click="logout()" class="boton-log-out no-mostrar"  v-if="store.user.nombre !== null">
        <!-- <img src="@/assets/user.png" alt="Imagen Usuario"> -->
        Logout
      </button>
    </nav>
  </header>
</template>

<script>
import store from '../store'
import authService from '../services/auth'
export default {
  name: 'Navegador',
  data: function () {
    return {
      store,
      url: '/',
      currentUrl: true,
      isActive: false
    }
  },
  // watch: {
  //   '$route' () {
  //     console.log('222222')
  //     this.isActive = !this.isActive
  //   }
  // },
  methods: {
    logout () {
      authService
        .logout()
        .then(rta => {
          this.store.user = {
            persona_id: null,
            socio_id: null,
            email: null,
            nombre: null,
            apellido: null,
            tipo_id: null,
            perfil_id: null,
            socio_numero: null,
            imagen: null,
            dni: null,
            direccion_1: null,
            provincia_id_1: null
          }
          // Redirecciono a la Home
          this.$router.push('/')
        })
    },
    menuHamburguesa () {
      console.log(document.querySelector('.icon-bars2'))
      console.log('wwwwww')
      this.isActive = !this.isActive
      // document.querySelector('.icon-bars2').toggleClass('open')
    },
    cerrarMenu () {
      console.log('qqqqq')
      this.isActive = !this.isActive
      document.getElementById('btn-menu').checked = false
    }
  },
  mounted: function () {
    console.log('refresh????')
    if (this.$route.name === 'Home' || this.$route.name === 'RecuperarPassword' || this.$route.name === 'ReseteraPassword') {
      this.currentUrl = false
      console.log('Current URL22222 => ' + this.currentUrl)
      console.log('Current URL22222 => ' + this.$route.name)
    } else {
      console.log('popopopo')
    }
  }
}
</script>
