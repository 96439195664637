<template>
  <li>
    <img :src="imagenRuta(item.producto_imagen)" :alt="item.producto_nombre">
    <div class="contenedor_info_productos_puntos_entrega">
      <div class="titulo_productos_puntos_entrega">
        {{ item.producto_nombre }}
      </div>
      <div class="info_productos_puntos_entrega">
        <div class="margin_left">
          {{ item.stock }} <span v-if="item.stock != 1">unidades</span> <span v-else>unidad</span>
        </div>
        <span class="margin_left">Talle: {{ item.talle }}</span>
        <span class="margin_left">Color: {{ item.color }}</span>
      </div>
    </div>
  </li>
</template>

<script>
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import store from './../store'
import { PATH_IMG } from '../constants'
// console.log(persona)
export default {
  name: 'PuntosDeEntregaListaItemProducto',
  components: {
  },
  props: ['item'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    imagenRuta (data) {
      return `${PATH_IMG}/${data}`
    }
  }
}
</script>

<style>

</style>
