<template>
  <div class="form_section">
    <label :for="`stock-${producto.producto_id}`">{{ producto.producto_nombre }}</label>
    <input
      type="text"
      :id="`stock-${producto.producto_id}`"
      name="stock"
      v-model="producto.stock"
      :disabled="cargando"
    >
    <div class="boton-borrar-persona" @click="borrarProducto(producto.puntos_de_entrega_tiene_producto_id)" >X</div>
    <div
    class="form_mensaje_error"
      v-if="errores2[numeroPosicion(producto.posicion) + '.stock']!== null"
    >
      {{ errorPosicion(producto.posicion) }}
    </div>
  </div>
</template>

<script>
import productosService from '../services/productos'
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import store from './../store'
// import { PATH_IMG } from '../constants'
// console.log('servicio', this.servicio)
export default {
  name: 'EditarStockPuntoDeEntrega',
  components: {
    // StockListaItemPersona
  },
  props: ['producto', 'errores2'],
  data: function () {
    return {
      pago: {
        stock: null
      },
      // errores2: {
      //   '1.stock': null
      // },
      cargando: false
    }
  },
  methods: {
    borrarProducto (id) {
      console.log('99888xxxxx')
      productosService
        .borrarProductoConStockPorId(id)
        .then(respuesta => {
          this.$emit('refrescar')
          // this.cargando = false
          // this.servicio = respuesta
          // console.log('Servicio', respuesta)
        })
    },
    errorPosicion (id) {
      // const x = id - 1
      return this.errores2[id + '.stock'][0]
      // return 'eeee'
    },
    numeroPosicion (id) {
      // const x = id - 1
      // console.log('Estoy en numeroCuota ' + x)
      return 0
      // return this.errores['cuotas.' + x + '.precio'][0] !== null
    }
  },
  mounted: function () {
    this.errores = {
      stock: 99
    }
  }
}
</script>

<style>

.boton-borrar-persona {
  padding: 8px;
  border-radius: 50%;
  background-color: red;
  color: white;
/*  display: inline-block;*/
  cursor: pointer;
  width: 28px;
  height: 28px;
  text-align: center;
/*  line-height: 20px;*/
  border: 1px solid red;
  display: flex;
  justify-content: center;
  font-weight: 740;
  font-size: 20px;
  margin-left: 15px;
}

.boton-borrar-persona:hover {
  background-color: white;
  color: red;
}

</style>
