<template>
  <div class="contenedor-editar-datos-socio">
    <div class="contenedor-titulo">
      <h1>Agregar Punto De Entrega</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/puntos-de-entrega`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section>
      <div class="contenedor-editar-datos-socio-izquierda">
        <img src="@/assets/patanjali.jpg" alt="Patanjali">
      </div>
      <div class="contenedor-editar-datos-socio-derecha">
        <p>Atención: Una persona que no es socia de la AAYI no puede ser punto de entrega</p>
        <form
          action="#"
          method="post"
          @submit.prevent="crearPuntoDeEntrega(socio)"
          class="form_presupuesto_nuevo"
        >
          <!-- <div class="form_section_editar_socio">
            <label for="nombre">Nombre</label>
            <input
              type="text"
              name="nombre"
              id="nombre"
              v-model="socio.nombre"
              :disabled="cargando"
            >
            <div
            class="form_editar_mensaje_error"
              v-if="errores.nombre !== null"
            >
              {{ errores.nombre[0] }}
            </div>
          </div> -->
          <div class="form_section_editar_socio">
            <label for="toma_clase">Elegir un nuevo punto de entrega</label>
            <div class="contenedor_select_buscador">
              <v-select
                class="select_buscador minimal"
                v-model.number="socio.persona_id"
                label="item_data"
                :reduce="socios => socios.persona_id"
                :options="socios"
                :disabled="cargando"
              >
                <span slot="no-options" @click="$refs.select.open = false" class="select_buscador_warning">
                  <p>⚠️ No figura en nuestros registros o ya esta en la lista de los Puntos de Entrega ⚠️</p>
                </span>
              </v-select>
            </div>
            <div
            class="form_editar_mensaje_error"
              v-if="errores.persona_id !== null"
            >
              {{ errores.persona_id[0] }}
            </div>
          </div>
          <button type="submit" class="boton_guardar">Crear Punto De Entrega</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import personasService from '../services/personas'
import asociadoService from '../services/asociado'
// import Menu from '../components/Menu.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'AgregarPuntoDeEntrega',
  components: {
  },
  data: function () {
    return {
      perfiles: [],
      socios: [],
      certificaciones: [],
      years: [],
      formasDePago: [],
      personas: [],
      monedas: [],
      cargando: false,
      // show: true,
      // seleccionado: 5,
      socio: {
        persona_id: null
      },
      errores: {
        persona_id: null
      }
    }
  },
  methods: {
    crearPuntoDeEntrega (socio) {
      console.log('qqqq')
      console.log(socio)
      if (this.cargando) {
        return null
      }
      this.errores = {
        persona_id: null
      }
      this.cargando = true
      personasService
        .crearPuntoDeEntrega(socio)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            this.socio = {
              persona_id: null
            }
            this.errores = {
              persona_id: null
            }
            this.$router.push('/puntos-de-entrega')
          } else {
            this.errores = {
              persona_id: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    asociadoService
      .traerPosiblesPuntosDeEntrega()
      .then(respuesta => {
        // this.cargando = false
        this.socios = respuesta
        console.log('listadoSociosSelect:', respuesta)
        this.socios.map(function (x) {
          // const pepe = x.nombre + ' ' + x.apellido
          // console.log(pepe)
          x.item_data = x.nombre + ' ' + x.apellido
          // return x.item_data = pepe
        })
      })
    console.log('aaaassssssaaaa')
    console.log(this.$route.query.collection_id)
    console.log(this.$route.query.pepe)
    personasService
      .traerPerfilesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.perfiles = respuesta
      })
    personasService
      .traerCertificacionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.certificaciones = respuesta
      })
  },
  computed: {
    mostrarCampoCertificacion: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.socio.perfil_id) {
        case 1:
          return true
        default:
          return false
      }
    }
  }
}
</script>
