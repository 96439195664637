<template>
  <div class="contenedor-difusion">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Difusión de Talleres</h1>
        <div class="barra"></div>
      </div>
      <section class="contenedor-login">
        <div class="contenedor-difusion-imagen">
          <img src="@/assets/geetaji.jpg" alt="Geeta Iyengar">
        </div>
        <div class="contenedor-difusion-text">
          <p>Queridxs socixs,</p>

          <p>Con enorme alegría les comentamos que la AAYI ofrece un nuevo beneficio a sus socixs!!!</p>

          <p>A partir del mes de marzo, podrán difundir las actividades o talleres que organicen, que vayan a ocurrir dentro del territorio argentino o uruguayo, relativas a la práctica de Asanas y/o a la filosofía del Yoga que sean de interés para nuestra comunidad.</p>

          <p>📢 La difusión será mediante el envío de un boletín con las actividades a toda nuestra base de correos y la publicación de una Historia en las redes sociales de la AAYI!!. Estas comunicaciones se harán el día 10 de cada mes o el lunes siguiente si cae en fin de semana o feriado. 📢</p>
          <div class="difusion-text-box">
            <div v-if="store.user.perfil_id == 1" class="contenedor_boton_difusion_profesor">
              <button
                v-if="store.user.perfil_id == 1"
                @click="abrirDocumento"
              >
                Clickea aquí para leer esta información para profesores
              </button>
              <div>
                <label for="checkbox">Leí la información importante para profesores
                  <input type="checkbox" id="checkbox" v-model="checked" @click="cambioEstado"/>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <p>Estas son las condiciones para utilizar el beneficio:</p>
            <p> - es válido para socixs con la cuota social anual al día</p>
            <p>- sólo se recibirá la información del 1-5 de cada mes</p>
            <p> - sólo se recibirá la información que completen en el siguiente formulario</p>

            <a
             href="https://forms.gle/C1pAp2eZVw3R8eLLA"
             target="_blank"
             id="botonFormulario"
             class="difusion-boton-form"
             >
              Llenar Formulario
            </a>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import store from '../store'
export default {
  name: 'Difusion',
  components: {
  },
  data: function () {
    return {
      store,
      checked: false
    }
  },
  methods: {
    abrirDocumento: function () {
      window.open('https://asociacioniyengar.com.ar/pdf/Difusion_del_Metodo.pdf', '_blank')
    },
    cambioEstado () {
      setTimeout(this.delayCambio, 400)
    },
    delayCambio () {
      var element = document.getElementById('botonFormulario')
      if (this.checked === true) {
        element.classList.remove('disabled-link')
        console.log('checked')
      } else {
        console.log('NOTTTT checked')
        element.classList.add('disabled-link')
      }
    }
  },
  mounted: function () {
    console.log('wwweee')
    if (this.store.user.perfil_id === 1) {
      console.log('profe')
      var element = document.getElementById('botonFormulario')
      element.classList.add('disabled-link')
    }
  }
}
</script>

<style>

</style>
