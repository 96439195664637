<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Listado de Productos</h1>
        <div class="barra"></div>
      </div>
      <ProductosLista
      ></ProductosLista>
    </section>
  </div>
</template>

<script>
import ProductosLista from '../components/ProductosLista.vue'
// import Header2 from '../components/Header2.vue'
export default {
  name: 'ListarProductos',
  components: {
    ProductosLista
    // Header2
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
