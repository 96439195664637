<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">LogIn</router-link>
      <router-link to="/hacerme-socio">INSCRIBIRME</router-link>
      <router-link to="/material-didactico">Material Didactico</router-link>
      <router-link to="/seminario-raya">RAYA</router-link>
      <router-link to="/socios">Listado de Socios</router-link>
      <router-link to="/stock">Stock</router-link>
      <router-link to="/ventas">Ventas</router-link>
      <router-link to="/crear-pago">Crear Pago</router-link>
      <router-link to="/crear-servicio">Crear Servicio</router-link>
      <router-link to="/crear-venta">Crear Venta</router-link>
      <router-link to="/crear-socio">Crear Socio</router-link>
      <router-link to="/listar-pagos">Listar Pagos</router-link>
      <router-link to="/panel-de-control">CPanel</router-link>
      <router-link to="/seminario-uday-socio">UDAY SOCIO</router-link>
      <router-link to="/convencion-2025-socio">Convencion 2025 socio</router-link>
      <button @click="logout()" class="boton-log-out">
        <img src="@/assets/user.png" alt="">
        <span>Logoutpp</span>
      </button>
    </div>
    <Navegador/>
    <BaseNotification
      v-if="status.msg != null"
      :type="status.type"
      :text="status.msg"
      :closable="status.closable"
      @close="borrarNotificacion()"
    />
    <router-view/>
  </div>
</template>

<script>
import store from './store'
import authService from './services/auth'
import Navegador from './components/Navegador'
import BaseNotification from './components/BaseNotification'
// ARCHIVVOS HARDCODEADO DE MP
// node_modules > mercadopago > lib > utils > mercadopagoError.js
// node_modules > mercadopago > lib > configuration.js
export default {
  components: {
    Navegador,
    BaseNotification
  },
  data: function () {
    return {
      store,
      status: {
        msg: null,
        type: 'success',
        closable: true
      }
    }
  },
  mounted () {
    this.store.user = authService.getLoggedUser()
    this.store.token = authService.getToken()
  },
  methods: {
    borrarNotificacion () {
      this.status = {
        msg: null,
        type: 'success',
        closable: true
      }
    },
    logout () {
      authService
        .logout()
        .then(rta => {
          this.store.user = {
            id: null,
            email: null,
            nombre: null,
            apellido: null,
            imagen: null
          }
          // Redirecciono a la Home
          this.$router.push('/')
        })
    }
  }
}
</script>

<style>

body {
  margin: auto;
  max-width: 1700px;
}

html {
  background-color: #EDF0EF;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
/*  font-family: 'Gothic A1', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #EDF0EF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#nav {
  padding: 30px;
  display: none;
/*  display: flex;*/
  justify-content: space-around;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
