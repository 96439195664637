<template>
  <div class="contenedor-editar-datos-socio">
    <div class="contenedor-titulo">
      <h1>Editar Punto De Entrega</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/puntos-de-entrega`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section>
      <div class="contenedor-editar-datos-socio-izquierda">
        <img src="@/assets/patanjali.jpg" alt="Patanjali">
      </div>
      <div class="contenedor-editar-datos-socio-derecha">
        <h2>{{ punto.persona_nombre }} {{ punto.persona_apellido }}</h2>
        <div>
          <form
            action="#"
            method="post"
            @submit.prevent="editarStock(punto.stock)"
            class="form_presupuesto_nuevo"
          >
            <!-- <p>Stock Total {{servicio.stock_total}}</p> -->
              <!-- <div class="form_section">
                <label for="stock">Stock Total</label>
                <input
                  type="number"
                  name="stock"
                  id="stock"
                  v-model="servicio.stock_total"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error"
                  v-if="errores.stock !== null"
                >
                  {{ errores.stock[0] }}
                </div>
              </div> -->
            <EditarStockPuntoDeEntrega
              v-for="producto in punto.stock"
              :producto="producto"
              :errores2="errores2"
              :key="producto.producto_id"
              @refrescar="refrescarEditarPuntoDeEntrega"
            ></EditarStockPuntoDeEntrega>
            <!-- <p>Depósito AAYI: {{servicio.stock_deposito_aayi}} unidades</p> -->
            <button type="submit" class="boton_guardar">Editar Stock</button>
          </form>
        </div>
        <div>
          <!-- <h2>Agregar Persona</h2> -->
          <form
            action="#"
            method="post"
            @submit.prevent="agregarProducto(producto)"
            class="form_presupuesto_nuevo"
          >
            <div class="form_section">
              <label for="persona_id">Producto</label>
              <select
                id="persona_id"
                name="persona_id"
                class="form minimal"
                v-model.number="producto.producto_id"
                :disabled="cargando"
              >
                <option
                  v-for="producto in productos"
                  :value="producto.producto_id"
                  :key="producto.producto_id"
                >
                  {{ producto.producto_nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error"
                v-if="errores.producto_id !== null"
              >
                {{ errores.producto_id[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="stock">Stock</label>
              <input
                type="text"
                name="stock"
                id="stock"
                v-model="producto.stock"
                :disabled="cargando"
              >
              <div
              class="form_mensaje_error"
                v-if="errores.stock !== null"
              >
                {{ errores.stock[0] }}
              </div>
            </div>
           <!--  <div class="form_section">
              <label for="fecha">Fecha del Ingreso</label>
              <input
                type="date"
                name="fecha"
                id="fecha"
                v-model="persona.fecha"
                :disabled="cargando"
              >
              <div
              class="form_mensaje_error"
                v-if="errores.fecha !== null"
              >
                {{ errores.fecha[0] }}
              </div>
            </div> -->
            <button type="submit" class="boton_guardar">Agregar Producto</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import entregasService from '../services/entregas'
// import serviciosService from '../services/servicios'
import productosService from '../services/productos'
import EditarStockPuntoDeEntrega from '../components/EditarStockPuntoDeEntrega.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'EditarPuntoDeEntrega',
  components: {
    EditarStockPuntoDeEntrega
  },
  data: function () {
    return {
      servicio: {
        stock: null
      },
      punto: {},
      personas: [],
      productos: [],
      producto: {
        producto_id: null,
        stock: null,
        punto_de_entrega_id: this.$route.params.id
      },
      persona: {
        persona_id: null,
        fecha: null,
        stock: null,
        servicio_id: this.$route.params.id
      },
      errores: {
        stock: null,
        producto_id: null
      },
      errores2: {
        // '0.stock': null,
        // producto_id: null
      },
      cargando: false
    }
  },
  methods: {
    agregarProducto (producto) {
      console.log(producto)
      productosService
        .agregarProducto(producto)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.refrescarEditarPuntoDeEntrega()
            this.producto = {
              producto_id: null,
              stock: null
            }
            this.errores = {
              producto_id: null,
              stock: null
            }
            this.$router.push('/puntos-de-entrega')
          } else {
            // console.log(respuesta.errores[0])
            this.errores = {
              stock: null,
              producto_id: null,
              ...respuesta.errors
            }
          }
        })
    },
    editarStock (punto) {
      console.log(punto)
      if (this.cargando) {
        return null
      }
      this.errores = {
        stock: null,
        producto_id: null
      }
      this.cargando = true
      productosService
        .editarStockPorPuntoDeEntregaId(punto)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.pago = {
              precio_total: null,
              fecha_de_pago: null,
              concepto_id: null,
              forma_de_pago_id: null,
              producto_id: null,
              year_id: null,
              moneda_id: null,
              descripcion: null
            }
            this.errores = {
              stock: null,
              producto_id: null
            }
            this.$router.push('/puntos-de-entrega')
          } else {
            console.log(respuesta.errors)
            this.errores2 = {
              stock: null,
              producto_id: null,
              ...respuesta.errors
            }
          }
        })
    },
    refrescarEditarPuntoDeEntrega () {
      productosService
        .traerPuntoDeEntregaPorId(this.$route.params.id)
        .then(respuesta => {
          // this.cargando = false
          this.punto = respuesta
          console.log('PUNTO DE ENTREGA', respuesta)
        })
      productosService
        .traerProductosFaltantesPuntoDeEntregaPorId(this.$route.params.id)
        .then(respuesta => {
          // this.cargando = false
          this.productos = respuesta
          console.log('Productos Faltantes: ', respuesta)
        })
    }
  },
  mounted: function () {
    // this.cargando = true
    // this.errores2 = {
    //   '0.stock': null,
    //   '1.stock': null
    // }
    productosService
      .traerPuntoDeEntregaPorId(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.punto = respuesta
        console.log('PUNTO DE ENTREGA', respuesta)
        for (var i = 0; i < respuesta.stock.length; i++) {
          this.errores2[i + '.stock'] = null
        }
      })
    productosService
      .traerProductosFaltantesPuntoDeEntregaPorId(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.productos = respuesta
        console.log('Productos Faltantes: ', respuesta)
      })
    // this.errores.productos.'/./'9'/./'.jkjk = null
  },
  // mounted: function () {
  //   pagosService
  //     .traerPago(this.$route.params.id)
  //     .then(respuesta => {
  //       console.log(respuesta)
  //       console.log(respuesta.years.length)
  //       // this.cargando = false
  //       this.pago = respuesta
  //       if (respuesta.years.length !== 0) {
  //         this.pago.year_id = respuesta.years[0].year_id
  //       }
  //     })
  //   pagosService
  //     .traerConceptosDePago()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.conceptos = respuesta
  //     })
  //   pagosService
  //     .traerYearsDePago()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.years = respuesta
  //     })
  //   pagosService
  //     .traerFormasDePago()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.formasDePago = respuesta
  //     })
  //   pagosService
  //     .traerMonedas()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.monedas = respuesta
  //     })
  //   pagosService
  //     .traerPersonas()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.personas = respuesta
  //       console.log('Personas: ', respuesta)
  //     })
  // },
  computed: {
    mostrarCampoYear: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.pago.concepto_id) {
        case 1:
          return true
        case 2:
          return true
        case 3:
          return true
        default:
          return false
      }
    }
  }
}
</script>
