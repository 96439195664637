import { API } from './../constants'
import authService from './auth.js'

let entrega = null
let personas = null
let formas = null

const entregasService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerEntregaPorId (id) {
    const fetchResponse = await fetch(`${API}/entrega/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    entrega = respuesta.data
    return { ...entrega }
  },
  async traerPersonasPorEntrega (id) {
    const fetchResponse = await fetch(`${API}/entrega-personas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    personas = respuesta.data
    return [...personas]
  },
  async traerPersonasSinStock (id) {
    const fetchResponse = await fetch(`${API}/personas-sin-stock/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    personas = respuesta.data
    return [...personas]
  },
  crearEntrega (data) {
    return fetch(`${API}/crear-entrega`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  async traerFormasDeEntrega () {
    const fetchResponse = await fetch(`${API}/formas-de-entrega`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    formas = respuesta.data
    return [...formas]
  }
}

export default entregasService
